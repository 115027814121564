import InnerButtonGroup from "components/InnerButtonGroup";
import OuterStatueSelector from "components/OuterStatueSelector";
import StatueConfig from "components/StatueConfig";
import { Shape2D } from "data/shapes";
import React, { useState } from "react";
import "components/InnerButtonGroup.scss";
import "./App.scss";

export type InnerStatueConfig = [
		undefined | Shape2D,
		undefined | Shape2D,
		undefined | Shape2D
]

function App() {
	const [ innerStatueConfig, setInnerStatueConfig ] =
		      useState<InnerStatueConfig>(
			      [ undefined, undefined, undefined ],
		      );


	return (
		<div className="App">
			<header className="App-header">
				<h1>Shapes Solver</h1>
				<StatueConfig />
				<div className={"credit"}>
					Made by <a href="https://nwl.gg" target={"_blank"} rel="noreferrer">nwL</a>.
				</div>
			</header>
		</div>
	);
}

export default App;
