import CubeIcon from "images/cube-svgrepo-com.svg";
import ConeIcon from "images/cone-svgrepo-com.svg";
import PyramidIcon from "images/tetrahedron-svgrepo-com.svg";
import CylinderIcon from "images/cylinder-svgrepo-com.svg";
import SphereIcon from "images/sphere-svgrepo-com.svg";
import PrismIcon from "images/prism-triangular-figure-form-geometry-graphic-svgrepo-com.svg";

export enum Shape2D {
	TRIANGLE = "TRIANGLE",
	CIRCLE   = "CIRCLE",
	SQUARE   = "SQUARE",
}

export enum Shape3D {
	// bad shapes
	PYRAMID  = "PYRAMID",
	SPHERE   = "SPHERE",
	CUBE     = "CUBE",
	// good shape =
	CONE     = "CONE",
	PRISM    = "PRISM",
	CYLINDER = "CYLINDER",
}

const ShapeIconMap: Map<Shape, string> = new Map( [
	[ Shape3D.CUBE, CubeIcon ],
	[ Shape3D.CONE, ConeIcon ],
	[ Shape3D.PYRAMID, PyramidIcon ],
	[ Shape3D.CYLINDER, CylinderIcon ],
	[ Shape3D.SPHERE, SphereIcon ],
	[ Shape3D.PRISM, PrismIcon ],
] );

export const getShapeIcon = ( s: Shape ) => {
	return ShapeIconMap.get( s );
};

export type Shape = Shape2D | Shape3D;

const shapeCombinationMap: Map<Shape2D, Map<Shape2D, Shape3D>> = new Map( [
	[ Shape2D.TRIANGLE, new Map( [
		[ Shape2D.TRIANGLE, Shape3D.PYRAMID ],
		[ Shape2D.CIRCLE, Shape3D.CONE ],
		[ Shape2D.SQUARE, Shape3D.PRISM ],
	] ) ],
	[ Shape2D.CIRCLE, new Map( [
		[ Shape2D.TRIANGLE, Shape3D.CONE ],
		[ Shape2D.CIRCLE, Shape3D.SPHERE ],
		[ Shape2D.SQUARE, Shape3D.CYLINDER ],
	] ) ],
	[ Shape2D.SQUARE, new Map( [
		[ Shape2D.TRIANGLE, Shape3D.PRISM ],
		[ Shape2D.CIRCLE, Shape3D.CYLINDER ],
		[ Shape2D.SQUARE, Shape3D.CUBE ],
	] ) ],
] );

export const combineShapes = ( shape1: Shape2D, shape2: Shape2D ): Shape3D => {
	return shapeCombinationMap.get( shape1 )!.get( shape2 )!;
};

export const combineShapesArray = ( shapes: [ Shape2D, Shape2D ] ) => combineShapes( shapes[0], shapes[1] );

export const dissectShape = ( shape: Shape3D ): [ Shape2D, Shape2D ] => {
	switch ( shape ) {
		case Shape3D.PYRAMID:
			return [ Shape2D.TRIANGLE, Shape2D.TRIANGLE ];
		case Shape3D.SPHERE:
			return [ Shape2D.CIRCLE, Shape2D.CIRCLE ];
		case Shape3D.CUBE:
			return [ Shape2D.SQUARE, Shape2D.SQUARE ];
		case Shape3D.CONE:
			return [ Shape2D.CIRCLE, Shape2D.TRIANGLE ];
		case Shape3D.PRISM:
			return [ Shape2D.SQUARE, Shape2D.TRIANGLE ];
		case Shape3D.CYLINDER:
			return [ Shape2D.SQUARE, Shape2D.CIRCLE ];
	}
};


export const getOpposingShapes = ( shape: Shape2D ): [ Shape2D, Shape2D ] =>
	// @ts-ignore
	[ Shape2D.TRIANGLE, Shape2D.CIRCLE, Shape2D.SQUARE ].filter( s => s !== shape );

export const getRequiredShapes = ( is: [ Shape2D, Shape2D ], target: [ Shape2D, Shape2D ] ) => {
	if ( target[0] === target[1] ) {
		throw new Error( "You're trying to double up shapes here. Nope." );
	}

	return target.filter( s => !is.includes( s ) );
};

/**
 * Assigns a score to a statue.
 *
 * - 0:   both shapes are wrong
 * - 1:   one shape is wrong
 * - 1.5: none of the shapes are wrong, but we doubled up one
 * - 2:   statue is perfect
 */
export const getStatueScore = ( statue: Shape3D | [ Shape2D, Shape2D ], innerShape: Shape2D ) => {
	const outerShapes = Array.isArray( statue ) ? statue : dissectShape( statue );

	if ( outerShapes[0] === outerShapes[1] ) {
		if ( innerShape === outerShapes[0] ) {
			// bad, BAD shape
			return 0;
		}

		// not bad, but not perfect
		return 1.5;
	}


	if ( outerShapes.includes( innerShape ) ) {
		// one shape still missing
		return 1;
	}

	// perfect statue
	return 2;
};

export const simulateSwap = ( statue: Shape3D | [ Shape2D, Shape2D ],
                              into: Shape2D,
                              out: Shape2D ): [ Shape2D, Shape2D ] => {
	const outerShapes = ( Array.isArray( statue ) ? statue : dissectShape( statue ) ).slice();

	if ( !outerShapes.includes( out ) ) {
		// what
		return outerShapes as [ Shape2D, Shape2D ];
	}

	const index = outerShapes.indexOf( out );
	outerShapes.splice( index, 1 );
	outerShapes.push( into );

	return outerShapes as [ Shape2D, Shape2D ];
};

export const ALL2DSHAPES = [
	Shape2D.TRIANGLE,
	Shape2D.CIRCLE,
	Shape2D.SQUARE,
];

// from https://stackoverflow.com/a/78196510
function uuidv4() {
	const s4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
		           .toString(16)
		           .substring(1);
	};
	return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export const wtfuuid = () => crypto.randomUUID?.() ?? uuidv4();
