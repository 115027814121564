import { ButtonInterface } from "components/InnerButtonGroup";
import { StatueConfigContext, StatueConfigUpdater } from "components/StatueConfig";
import { getShapeIcon, Shape3D, wtfuuid } from "data/shapes";
import React, { Fragment, useContext } from "react";
import "./ButtonGroup.scss";

const OuterStatuePossibilities: ButtonInterface<Shape3D>[] = [
	{
		label: "Pyramid",
		value: Shape3D.PYRAMID,
	},
	{
		label: "Sphere",
		value: Shape3D.SPHERE,
	},
	{
		label: "Cube",
		value: Shape3D.CUBE,
	},
	{
		label: "Cone",
		value: Shape3D.CONE,
	},
	{
		label: "Prism",
		value: Shape3D.PRISM,
	},
	{
		label: "Cylinder",
		value: Shape3D.CYLINDER,
	},
];

export default function OuterButtonGroup( props: {
	position: number
} ) {
	const groupUUID = wtfuuid();

	const statueState = useContext( StatueConfigContext );

	const currentState = statueState.config.outer[props.position];

	return (
		<div className={"button-group OuterButtonGroup"}>
			{OuterStatuePossibilities.map( b => {
				const buttonUUID = wtfuuid();

				let updateValue: StatueConfigUpdater = {
					outer: [ null, null, null ],
				};

				updateValue.outer!.splice( props.position, 1, b.value );

				return <Fragment key={buttonUUID}>
					<input
						id={buttonUUID}
						name={groupUUID}
						type="radio"
						value={b.value}
						onChange={() => statueState.update( updateValue )}
						checked={b.value === currentState}
					/>
					<label htmlFor={buttonUUID} className="button">
						{getShapeIcon( b.value ) ?
							<img className={"OuterButtonGroup__icon"} src={getShapeIcon( b.value )} alt={""} /> : null}
						<span className={"OuterButtonGroup__labeltext"}>{b.label}</span>
					</label>
				</Fragment>;
			} )}
		</div>
	);
};
