import { StatueConfigContext, StatueConfigUpdater } from "components/StatueConfig";
import { getShapeIcon, Shape2D, wtfuuid } from "data/shapes";
import React, { Fragment, useContext } from "react";
import "./ButtonGroup.scss";

export interface ButtonInterface<T> {
	value: T,
	label: string,
}

const InnerStatuePossibilities: ButtonInterface<Shape2D>[] = [
	{
		label: "△",
		value: Shape2D.TRIANGLE,
	},
	{
		label: "○",
		value: Shape2D.CIRCLE,
	},
	{
		label: "◻",
		value: Shape2D.SQUARE,
	},
];

export default function InnerButtonGroup( props: {
	position: number
} ) {
	const groupUUID = wtfuuid();
	const statueState = useContext( StatueConfigContext );

	const currentState = statueState.config.inner[props.position];

	return (
		<div className={"button-group OuterButtonGroup InnerButtonGroup"}>
			{InnerStatuePossibilities.map( b => {
				const buttonUUID = wtfuuid();

				let updateValue: StatueConfigUpdater = {
					inner: [ null, null, null ],
				};

				updateValue.inner!.splice( props.position, 1, b.value );

				return <Fragment key={buttonUUID}>
					<input
						id={buttonUUID}
						name={groupUUID}
						type="radio"
						value={b.value}
						onChange={() => statueState.update( updateValue )}
						checked={b.value === currentState}
					/>
					<label htmlFor={buttonUUID} className="button">
						{getShapeIcon( b.value ) ?
							<img className={"OuterButtonGroup__icon"} src={getShapeIcon( b.value )} alt={""} /> : null}
						{b.label}
					</label>
				</Fragment>;
			} )}
		</div>
	);
};
