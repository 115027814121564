import InnerButtonGroup from "components/InnerButtonGroup";
import React from "react";

function InnerStatueSelector() {
	return <div className="InnerStatueSelector">
		<InnerButtonGroup position={0} />
		<InnerButtonGroup position={1} />
		<InnerButtonGroup position={2} />
	</div>;
}

export default InnerStatueSelector;
