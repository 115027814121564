import { StatueConfigContext } from "components/StatueConfig";
import { combineShapesArray, getShapeIcon, Shape2D } from "data/shapes";
import React, { useContext } from "react";
import OuterButtonGroup from "components/OuterButtonGroup";
import "./OuterStatueSelector.scss";


export default function OuterStatueSelector() {
	const statueState = useContext( StatueConfigContext );

	const desiredShapes = statueState.config.inner.map( s => {
		if ( s === undefined ) {
			return undefined;
		}

		const resultingShapes = [
			Shape2D.CIRCLE,
			Shape2D.TRIANGLE,
			Shape2D.SQUARE,
		].filter( x => x !== s );

		// @ts-ignore
		return combineShapesArray( resultingShapes );
	} );


	return <div className="OuterStatueSelector">
		{[ 0, 1, 2 ].map( i => <div key={`OuterStatueSelectorElement${i}`} className="OuterStatueSelector__column">
			<OuterButtonGroup position={i} />
			<span>↓</span>
			{
				desiredShapes[i]
					? <img className="OuterStatueSelector__resultIcon" src={getShapeIcon( desiredShapes[i]! )} />
					: null
			}
		</div> )}
	</div>;
}
