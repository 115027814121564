import Explainer from "components/Explainer";
import { isConfigComplete, StatueConfigContext, StatueConfigState } from "components/StatueConfig";
import { ALL2DSHAPES, combineShapesArray, dissectShape, Shape2D, Shape3D } from "data/shapes";
import React, { useContext } from "react";
import s from "./ExplainerWrapper.module.scss";

export enum StepTypes {
	DUNK    = "DUNK",
	KNIGHTS = "KNIGHTS",
}

interface DunkStep {
	type: StepTypes.DUNK,
	shape: Shape2D;
	statue: number;
}

interface KnightStep {
	type: StepTypes.KNIGHTS;
}

type Step = DunkStep | KnightStep;

export type Steps = Step[];

class ShapeDropState {
	private readonly initialShapes = [ Shape2D.TRIANGLE, Shape2D.CIRCLE, Shape2D.SQUARE ];
	private shapes: Shape2D[] = [];

	reset( steps: Steps ) {
		steps.push( { type: StepTypes.KNIGHTS } );
		this.shapes = this.initialShapes.slice();
	}

	get = () => this.shapes;
	amount = () => this.shapes.length;
	pickUp = ( shape: Shape2D ) => this.shapes.splice( this.shapes.indexOf( shape ), 1 );
}

class StatueSimulatorState {
	private activated?: { statue: number, shape: Shape2D } = undefined;

	constructor( private statues: [ Shape3D, Shape3D, Shape3D ] ) {}

	public dunk( statue: number, shape: Shape2D, steps: Steps ) {
		if ( this.activated?.statue === statue ) {
			throw new Error( "Tried to dunk on an already activated statue!" );
		}

		if ( !dissectShape( this.statues[statue] ).includes( shape ) ) {
			throw new Error( `Tried to dunk ${shape} on ${this.statues[statue]}, impossible!` );
		}

		if ( this.activated?.shape === shape ) {
			throw new Error( `Dunking ${shape} would change nothing (already active on statue ${this.activated.statue}).` );
		}

		if ( this.activated ) {
			const firstStatue = dissectShape( this.statues[this.activated.statue] );
			const firstDissectIndex = firstStatue.indexOf( this.activated.shape );
			const first3DShape = combineShapesArray( [ firstStatue[1 - firstDissectIndex], shape ] );

			const secondStatue = dissectShape( this.statues[statue] );
			const secondDissectIndex = secondStatue.indexOf( shape );
			const second3DShape = combineShapesArray(
				[ secondStatue[1 - secondDissectIndex], this.activated.shape ],
			);

			this.statues[this.activated.statue] = first3DShape;
			this.statues[statue] = second3DShape;
			this.activated = undefined;
		} else {
			this.activated = { statue, shape };
		}

		steps.push( {
			type: StepTypes.DUNK,
			statue, shape,
		} );
	}

	get(): StatueSimulatorState["statues"];
	get( statue: number ): StatueSimulatorState["statues"][number];

	get( statue?: number ): StatueSimulatorState["statues"] | StatueSimulatorState["statues"][number] {
		if ( statue === undefined ) {
			return this.statues;
		}

		return this.statues[statue];
	}
}

class Solver {
	private dropState: ShapeDropState = new ShapeDropState();
	public steps: Steps = [];

	constructor( private sim: StatueSimulatorState, private target: [ Shape2D, Shape2D, Shape2D ] ) {}

	private needs( statue: number, shape: Shape2D ) {
		const currentShapes = dissectShape( this.sim.get( statue ) );

		// it needs it if:
		// - it's not the shape we don't want
		// - we don't already have one

		return ALL2DSHAPES.filter( s => s !== this.target[statue] ).includes( shape ) &&
			!currentShapes.includes( shape );
	}

	private canTakeFrom( statue: number, shape: Shape2D ) {
		const currentShapes = dissectShape( this.sim.get( statue ) );

		if ( !currentShapes.includes( shape ) ) {
			return false;
		}

		// can take if:
		// - it's the bad shape
		// - or we have two of the same

		return this.target[statue] === shape || (
			currentShapes[0] === shape
			&& currentShapes[0] === currentShapes[1]
		);
	}

	private findPairing( shape1: Shape2D, shapes2?: [ Shape2D ] ) {
		const availableShapes = shapes2 ??
			[ Shape2D.TRIANGLE, Shape2D.CIRCLE, Shape2D.SQUARE ].filter( s => s !== shape1 );

		for ( const shape2 of availableShapes ) {
			for ( let firstStatue = 0; firstStatue < 3; firstStatue++ ) {
				const needsFirstShape = this.needs( firstStatue, shape1 );
				const canTakeSecondShape = this.canTakeFrom( firstStatue, shape2 );

				console.debug( `Offering ${shape1} to ${firstStatue}, taking ${shape2} (needs: ${needsFirstShape}, canTake: ${canTakeSecondShape})` );

				if ( !needsFirstShape || !canTakeSecondShape ) {
					continue;
				}

				for ( let secondStatue = 0; secondStatue < 3; secondStatue++ ) {
					if ( firstStatue === secondStatue ) {
						continue;
					}

					const needsSecondShape = this.needs( secondStatue, shape2 );
					const canTakeFirstShape = this.canTakeFrom( secondStatue, shape1 );

					console.debug( `Offering ${shape1} to ${firstStatue}, taking ${shape2} (needs: ${needsFirstShape}, canTake: ${canTakeSecondShape}),
offering ${shape2} to ${secondStatue}, taking ${shape1} (needs: ${needsSecondShape}, canTake: ${canTakeFirstShape})` );

					if ( needsSecondShape && canTakeFirstShape ) {
						return [
							{
								statue: secondStatue,
								shape: shape1,
							},
							{
								statue: firstStatue,
								shape: shape2,
							},
						];
					}
				}
			}
		}

		return null;
	}

	private isSolved() {
		return this.sim.get().map( dissectShape ).every( ( s, i ) =>
			!s.includes( this.target[i] ) );
	}


	public solve() {
		// three possibilites: three left, two left, one left
		// there can't be zero left because that'd underflow to three
		// but we check anyways Just In Case

		let pair;
		let solvedThree;
		while ( !this.isSolved() ) {
			pair = null;
			switch ( this.dropState.amount() ) {
				case 3:
					solvedThree = false;
					for ( const shape of this.dropState.get() ) {
						console.log( "3 - ", shape );
						pair = this.findPairing( shape );
						if ( !pair ) {
							continue;
						}
						this.dropState.pickUp( pair[0].shape );
						this.sim.dunk( pair[0].statue, pair[0].shape, this.steps );
						this.dropState.pickUp( pair[1].shape );
						this.sim.dunk( pair[1].statue, pair[1].shape, this.steps );
						console.log( this.steps );
						console.log( this.sim.get() );
						solvedThree = true;
						break;
					}
					if ( !solvedThree ) {
						console.warn( "Falling back to manual mode." );
						// there is a rare possibility that all three symbols soft-lock each other, so we attempt one
						// more manual try

						for ( let firstStatue = 0; firstStatue < 3; firstStatue++ ) {
							if ( dissectShape( this.sim.get( firstStatue ) ).includes( Shape2D.TRIANGLE ) &&
								dissectShape( this.sim.get( firstStatue ) ).includes( Shape2D.CIRCLE ) ) {
								console.warn( `Found statue ${firstStatue} having T+C, shape is ${this.target[firstStatue]}` );
								if ( this.target[firstStatue] === Shape2D.TRIANGLE ) {
									// we want to give away TRIANGLE
									// so we look for CIRCLE
									for ( let secondStatue = 0; secondStatue < 3; secondStatue++ ) {
										if ( this.target[secondStatue] === Shape2D.CIRCLE ) {
											this.dropState.pickUp( Shape2D.TRIANGLE );
											this.sim.dunk( firstStatue, Shape2D.TRIANGLE, this.steps );
											this.dropState.pickUp( Shape2D.CIRCLE );
											this.sim.dunk( secondStatue, Shape2D.CIRCLE, this.steps );
											solvedThree = true;
										}
									}
								} else {
									// we want to give away CIRCLE
									// so we look for TRIANGLE
									for ( let secondStatue = 0; secondStatue < 3; secondStatue++ ) {
										if ( this.target[secondStatue] === Shape2D.TRIANGLE ) {
											this.dropState.pickUp( Shape2D.CIRCLE );
											this.sim.dunk( firstStatue, Shape2D.CIRCLE, this.steps );
											this.dropState.pickUp( Shape2D.TRIANGLE );
											this.sim.dunk( secondStatue, Shape2D.TRIANGLE, this.steps );
											solvedThree = true;
										}
									}
								}
							}
						}
					}

					if ( !solvedThree ) {
						throw new Error( "No pairing found." );
					}

					break;

				case 2:
					pair = this.findPairing( this.dropState.get()[0], [ this.dropState.get()[1] ] );
					if ( !pair ) {
						throw new Error( "No pairing found." );
					}
					this.dropState.pickUp( pair[0].shape );
					this.sim.dunk( pair[0].statue, pair[0].shape, this.steps );
					this.dropState.pickUp( pair[1].shape );
					this.sim.dunk( pair[1].statue, pair[1].shape, this.steps );
					console.log( this.steps );
					console.log( this.sim.get() );

					break;
				case 1:
					pair = this.findPairing( this.dropState.get()[0] );
					if ( !pair ) {
						throw new Error( "No pairing found." );
					}
					this.dropState.pickUp( pair[0].shape );
					this.sim.dunk( pair[0].statue, pair[0].shape, this.steps );
					this.dropState.reset( this.steps );
					this.dropState.pickUp( pair[1].shape );
					this.sim.dunk( pair[1].statue, pair[1].shape, this.steps );
					console.log( this.steps );
					console.log( this.sim.get() );

					break;
				case 0:
					this.dropState.reset( this.steps );
			}
		}
	}

}

export default function ExplainerWrapper() {
	const config: StatueConfigState = structuredClone( useContext( StatueConfigContext ).config );

	if ( !isConfigComplete( config ) ) {
		return <div className={s.rootError}>Incomplete information.</div>;
	}

	// check if all three top ones are correct
	if ( config.inner.filter( function ( item, pos ) {
		return config.inner.indexOf( item ) === pos;
	} ).length !== config.inner.length ) {
		return <div className={s.rootError}>No inner statues can be the same!</div>;
	}

	// check if all three top ones are correct

	const all2DFrom3D = config.outer.map( dissectShape ).flat();

	if (
		all2DFrom3D.filter( x => x === Shape2D.TRIANGLE ).length !== 2
		|| all2DFrom3D.filter( x => x === Shape2D.CIRCLE ).length !== 2
		|| all2DFrom3D.filter( x => x === Shape2D.SQUARE ).length !== 2
	) {
		return <div className={s.rootError}>Outer puzzle is impossible! Recheck shapes.</div>;
	}

	// alright, all looks good, let's get to solving

	const boxSimulator = new StatueSimulatorState( config.outer );
	console.debug( config.inner );
	const solver = new Solver( boxSimulator, config.inner );
	try {
		solver.solve();
	} catch ( e: any ) {
		console.error( e );
		console.warn( solver.steps );
	}

	console.log( solver.steps );

	return <Explainer steps={solver.steps} />;
}
