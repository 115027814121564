import { Steps, StepTypes } from "components/ExplainerWrapper";
import { Shape2D } from "data/shapes";
import React from "react";

import s from "./ExplainerList.module.scss";

enum Side {
	LEFT,
	MIDDLE,
	RIGHT,
}

interface Callout {
	label: string,
	iconography: string,
}

type SideDetails = Callout;

type ShapeDetails = Callout & {
	knightSide: Side
}

const sideMap = new Map<Side, SideDetails>( [
	[ Side.LEFT, {
		label: "LEFT",
		iconography: "▣▢▢",
	} ],
	[ Side.MIDDLE, {
		label: "MIDDLE",
		iconography: "▢▣▢",
	} ],
	[ Side.RIGHT, {
		label: "RIGHT",
		iconography: "▢▢▣",
	} ],
] );

const shapeMap = new Map<Shape2D, ShapeDetails>( [
	[ Shape2D.TRIANGLE, {
		label: "TRIANGLE",
		iconography: "△",
		knightSide: Side.MIDDLE,
	} ],
	[ Shape2D.CIRCLE, {
		label: "CIRCLE",
		iconography: "○",
		knightSide: Side.LEFT,
	} ],
	[ Shape2D.SQUARE, {
		label: "SQUARE",
		iconography: "◻",
		knightSide: Side.RIGHT,
	} ],
] );

const InlineShape = ( props: React.PropsWithoutRef<{
	shape: Shape2D
}> ) => {
	return <span className={s.inline}>
		<code className={s.inlineShape} data-shape={props.shape}>
			{shapeMap.get( props.shape )?.iconography}
		</code>
		{/*<code>{shapeMap.get( props.shape )?.label.padEnd( 8, "\u00A0" )}</code>*/}
		<span>{shapeMap.get( props.shape )?.label}</span>
	</span>;
};

const InlineDirection = ( props: React.PropsWithoutRef<{
	side: number
}> ) => {
	return <span className={s.inline}>
		<code className={s.inlineSide}>
			{sideMap.get( props.side )?.iconography}
		</code>
		<span>{sideMap.get( props.side )?.label}</span>
	</span>;
};

const InlineKnightSide = ( props: React.PropsWithoutRef<{
	shape: Shape2D
}> ) => {
	const side = shapeMap.get( props.shape )!.knightSide;

	return <span className={s.inline}>
		<span>{sideMap.get( side )?.label}</span>
	</span>;
};

const InlineKnightStep = () => {

	return <li className={s.knights}>
		<div>
			<div></div>
		</div>
		<span>Kill Unstoppables.</span>
		<div>
			<div></div>
		</div>
	</li>;
};

function Explainer( props: {
	steps: Steps
} ) {
	if ( props.steps.length === 0 ) {
		return <div className={s.root}>
			<h2>Steps to solve</h2>
			None, the puzzle is already done!
		</div>;
	}

	const stepList = [];

	if ( props.steps[0].type === StepTypes.KNIGHTS ) {
		props.steps.shift();
	}

	for ( const step of props.steps ) {
		if ( step.type === StepTypes.KNIGHTS ) {
			stepList.push( <InlineKnightStep /> );
			continue;
		}

		stepList.push(
			<li className={s.step}>
				Kill {<InlineKnightSide shape={step.shape} />} Knight.<br />
				Dunk <InlineShape shape={step.shape} /> into <InlineDirection side={step.statue} /> statue.
			</li>,
		);
	}

	return <div className={s.root}>
		<h2>Steps to solve</h2>
		<ol className={s.list}>{stepList}</ol>
	</div>;
}

export default Explainer;
