import ExplainerWrapper from "components/ExplainerWrapper";
import InnerStatueSelector from "components/InnerStatueSelector";
import OuterStatueSelector from "components/OuterStatueSelector";
import { Shape2D, Shape3D } from "data/shapes";
import React, { useState } from "react";

export interface StatueConfigState {
	inner: [ Shape2D | undefined, Shape2D | undefined, Shape2D | undefined ],
	outer: [ Shape3D | undefined, Shape3D | undefined, Shape3D | undefined ],
}

export interface CompleteStatueConfig {
	inner: [ Shape2D, Shape2D, Shape2D ],
	outer: [ Shape3D, Shape3D, Shape3D ]
}

export function isConfigComplete( config: StatueConfigState ): config is CompleteStatueConfig {
	return config.inner.every( e => e !== undefined ) || config.outer.every( e => e !== undefined );
}

export interface StatueConfigUpdater {
	inner?: [ Shape2D | null, Shape2D | null, Shape2D | null ],
	outer?: [ Shape3D | null, Shape3D | null, Shape3D | null ],
}

export const StatueConfigContext = React.createContext<{
	config: StatueConfigState,
	update: ( config: StatueConfigUpdater ) => void;
}>( {
	config: {
		inner: [ undefined, undefined, undefined ],
		outer: [ undefined, undefined, undefined ],
	},
	update: () => {},
} );

function StatueConfig() {
	const [ statueState, setStatueState ] = useState<StatueConfigState>( {
		inner: [ undefined, undefined, undefined ],
		outer: [ undefined, undefined, undefined ],
	} satisfies StatueConfigState );

	// yes, this is awful. yes, this works.
	const updateState = ( state: StatueConfigUpdater ) => {
		setStatueState( current => {
			let copy = { ...current };

			if ( state?.inner ) {
				// @ts-ignore
				copy.inner = copy.inner.map( ( e, i ) => {
					return state.inner![i] === null ? e : state.inner![i];
				} );
			}
			if ( state?.outer ) {
				// @ts-ignore
				copy.outer = copy.outer.map( ( e, i ) => {
					return state.outer![i] === null ? e : state.outer![i];
				} );
			}

			return copy;
		} );
	};

	return <StatueConfigContext.Provider value={{ config: statueState, update: updateState }}>
		<button className={"ResetButton"} onClick={() => setStatueState({
			inner: [ undefined, undefined, undefined ],
			outer: [ undefined, undefined, undefined ],
		})}>Reset</button>
		<InnerStatueSelector />
		<OuterStatueSelector />
		<ExplainerWrapper />
	</StatueConfigContext.Provider>;
}

export default StatueConfig;
